<template>
	<v-flex style="overflow: auto">
		<v-data-table
			height="65vh"
			:headers="isAdmin ? headers_admin : headers_basic"
			:items="records"
			:items-per-page="-1"
			:search="filter_value"
			:custom-filter="customFilter"
			item-key="cas"
			disable-sort
			hide-default-footer
			fixed-header
			class="planovac"
		>
			<template v-slot:body="{ items }">
				<tbody>
					<tr
						v-for="item in items"
						:key="item.id"
						@mouseover="setItemActive(item)"
					>
						<td style="margin: 0px; padding: 0px" class="table-cell-border">
							<v-lazy transition="">
								<v-text-field
									:value="item.cas"
									flat
									solo
									hide-details
									background-color="frozen-col-background"
									class="table-cell"
									readonly
									:height="cell_height"
								></v-text-field>
							</v-lazy>
						</td>

						<td style="margin: 0px; padding: 0px" class="table-cell-border">
							<SelectEditCell
								v-if="isAdmin"
								:item="item"
								col="status_auta"
								:height="cell_height"
								:rowChanged="rowChanged"
								:items="status_auta_items"
							/>
							<TextEditCell
								v-else
								:item="item"
								col="status_auta"
								:height="cell_height"
								:rowChanged="rowChanged"
								:isDisabled="true"
							/>
						</td>

						<td
							v-if="isAdmin"
							style="margin: 0px; padding: 0px"
							class="table-cell-border"
						>
							<TextEditCell
								:item="item"
								col="spz"
								:height="cell_height"
								:rowChanged="rowChanged"
							/>
						</td>

						<td style="margin: 0px; padding: 0px" class="table-cell-border">
							<TextEditCell
								:item="item"
								col="vozidlo"
								:height="cell_height"
								:rowChanged="rowChanged"
								:isDisabled="
									!isAdmin &&
										(isDisabled ||
											(item.technik !== '' && item.technik !== display_name))
								"
							/>
						</td>

						<td style="margin: 0px; padding: 0px" class="table-cell-border">
							<AutoCompleteEditCell
								:item="item"
								col="zakaznik"
								:height="cell_height"
								:rowChanged="rowChanged"
								:items="zakaznik_items"
								:isDisabled="
									!isAdmin &&
										(isDisabled ||
											(item.technik !== '' && item.technik !== display_name))
								"
							/>
						</td>

						<td
							v-if="isAdmin"
							style="margin: 0px; padding: 0px"
							class="table-cell-border"
						>
							<SelectEditCell
								:item="item"
								col="zakaznik_kategoria"
								:height="cell_height"
								:rowChanged="rowChanged"
								:items="zakaznik_kategoria_items"
							/>
						</td>

						<td
							v-if="isAdmin"
							style="margin: 0px; padding: 0px"
							class="table-cell-border"
						>
							<TextEditCell
								:item="item"
								col="vozidlo_kategoria"
								:height="cell_height"
								:rowChanged="rowChanged"
							/>
						</td>

						<td style="margin: 0px; padding: 0px" class="table-cell-border">
							<AutoCompleteEditCell
								:item="item"
								col="typ_kontroly"
								:height="cell_height"
								:rowChanged="rowChanged"
								:items="typ_kontroly_items"
								:isDisabled="
									!isAdmin &&
										(isDisabled ||
											(item.technik !== '' && item.technik !== display_name))
								"
							/>
						</td>

						<td style="margin: 0px; padding: 0px" class="table-cell-border">
							<TextEditCell
								:item="item"
								col="kontakt"
								:height="cell_height"
								:rowChanged="rowChanged"
								:isDisabled="
									!isAdmin &&
										(isDisabled ||
											(item.technik !== '' && item.technik !== display_name))
								"
							/>
						</td>

						<td style="margin: 0px; padding: 0px" class="table-cell-border">
							<SelectEditCell
								v-if="isAdmin"
								:item="item"
								col="technik"
								:height="cell_height"
								:rowChanged="rowChanged"
								:items="technici"
							/>
							<TextEditCell
								v-else
								:item="item"
								col="technik"
								:height="cell_height"
								:rowChanged="rowChanged"
								:isDisabled="true"
							/>
						</td>

						<td
							v-if="isAdmin"
							style="margin: 0px; padding: 0px"
							class="table-cell-border"
						>
							<TextEditCell
								:item="item"
								col="suma"
								:height="cell_height"
								:rowChanged="rowChanged"
							/>
						</td>
						<td
							v-if="isAdmin"
							style="margin: 0px; padding: 0px"
							class="table-cell-border"
						>
							<TextEditCell
								:item="item"
								col="datum_vyplatenia"
								:height="cell_height"
								:rowChanged="rowChanged"
							/>
						</td>

						<td
							v-if="isAdmin"
							style="margin: 0px; padding: 0px"
							class="table-cell-border"
						>
							<TextEditCell
								:item="item"
								col="suma_zlava"
								:height="cell_height"
								:rowChanged="rowChanged"
							/>
						</td>

						<td
							v-if="isAdmin"
							style="margin: 0px; padding: 0px"
							class="table-cell-border"
						>
							<SelectEditCell
								:item="item"
								col="platba"
								:height="cell_height"
								:rowChanged="rowChanged"
								:items="platba_items"
							/>
						</td>

						<td
							v-if="isAdmin"
							style="margin: 0px; padding: 0px"
							class="table-cell-border"
						>
							<SelectEditCell
								:item="item"
								col="vysledok_tk"
								:height="cell_height"
								:rowChanged="rowChanged"
								:items="vysledok_tk_items"
							/>
						</td>

						<td
							v-if="isAdmin"
							style="margin: 0px; padding: 0px"
							class="table-cell-border"
						>
							<SelectEditCell
								:item="item"
								col="vysledok_ek"
								:height="cell_height"
								:rowChanged="rowChanged"
								:items="vysledok_ek_items"
							/>
						</td>

						<td
							v-if="isAdmin"
							style="margin: 0px; padding: 0px"
							class="table-cell-border"
						>
							<TextEditCell
								:item="item"
								col="technik_body"
								:height="cell_height"
								:rowChanged="rowChanged"
							/>
						</td>

						<td
							v-if="isAdmin"
							style="margin: 0px; padding: 0px"
							class="table-cell-border"
						>
							<TextEditCell
								:item="item"
								col="technik_tringelt"
								:height="cell_height"
								:rowChanged="rowChanged"
							/>
						</td>

						<td
							v-if="isAdmin"
							style="margin: 0px; padding: 0px"
							class="table-cell-border"
						>
							<TextEditCell
								:item="item"
								col="poznamka"
								:height="cell_height"
								:rowChanged="rowChanged"
							/>
						</td>

						<td
							v-if="isAdmin"
							style="margin: 0px; padding: 0px"
							class="table-cell-border"
						>
							<TextEditCell
								:item="item"
								col="cakanie"
								isDisabled
								:height="cell_height"
								:rowChanged="rowChanged"
							/>
						</td>
					</tr>
				</tbody>
			</template>
		</v-data-table>

		<v-snackbar v-model="snackbar" color="error" timeout="4000">
			Záznam sa neuložil, pretože kontakt musí byť tel. číslo.

			<template v-slot:action="{ attrs }">
				<v-btn text v-bind="attrs" @click="snackbar = false"> Ok </v-btn>
			</template>
		</v-snackbar>
	</v-flex>
</template>

<script>
import axios from "axios"

import { mapGetters } from "vuex"

import TextEditCell from "@/views/components/table/TextEditCell.vue"
import SelectEditCell from "@/views/components/table/SelectEditCell.vue"
import AutoCompleteEditCell from "@/views/components/table/AutoCompleteEditCell.vue"

import config from "@/data/config.js"
import { tableSearchWithDate } from "@/data/api.js"

class Record {
	id
	color = ""
	isActive = false
	isDisabl

	cas
	datum_objednania = ""
	#status_auta = ""
	spz = ""
	vozidlo = ""
	vozidlo_kategoria = ""
	zakaznik = ""
	zakaznik_kategoria = ""
	typ_kontroly = ""
	kontakt = ""
	technik = ""
	suma = ""
	datum_vyplatenia = ""
	suma_zlava = ""
	platba = ""
	vysledok_tk = ""
	vysledok_ek = ""
	technik_body = ""
	technik_tringelt = ""

	kedy_prislo = ""
	cakanie = ""
	poznamka = ""

	constructor(data) {
		Object.assign(this, data)
	}

	get status_auta() {
		return this.#status_auta
	}

	set status_auta(status) {
		this.#status_auta = status
		this.color = config.STATUS_COLOR_NEW[this.status_auta]
	}

	setRefreshColor() {
		this.color = config.STATUS_COLOR_NEW["refresh"]
	}

	getSelfData() {
		let jsonObj = Object.assign({}, this)
		jsonObj.status_auta = this.status_auta

		delete jsonObj.color
		delete jsonObj.cas
		delete jsonObj.isActive
		delete jsonObj.nazov_tabulky

		return jsonObj
		//return JSON.stringify(data)
	}

	rowUpdateColor() {
		this.setRefreshColor()
		setTimeout(() => {
			this.status_auta = this.#status_auta
		}, 500)
	}
}

export default {
	name: "TempTable",

	props: ["table", "count", "delay", "ws_data"],

	components: {
		TextEditCell,
		SelectEditCell,
		AutoCompleteEditCell,
	},

	data() {
		return {
			isDisabled: false,
			snackbar: false,

			cell_height: "30",

			headers_admin: [
				{
					text: "",
					value: "cas",
					width: 60,
					divider: true,
					class: "header",
				},
				{
					text: "status auta",
					align: "center",
					value: "status_auta",
					width: 120,
					divider: true,
					class: "header",
				},
				{
					text: "ŠPZ",
					align: "center",
					value: "spz",
					width: 110,
					divider: true,
					class: "header",
				},
				{
					text: "vozidlo\n(povinné)",
					align: "center",
					value: "vozidlo",
					width: 120,
					divider: true,
					class: "header",
				},
				{
					text: "zákazník\n(povinné)",
					align: "center",
					value: "zakaznik",
					width: 120,
					divider: true,
					class: "header",
				},
				{
					text: "kat. zak.\n(povinné)",
					align: "center",
					value: "zakaznik_kategoria",
					width: 55,
					divider: true,
					class: "header",
				},
				{
					text: "kat.\nvoz.",
					align: "center",
					value: "vozidlo_kategoria",
					width: 55,
					divider: true,
					class: "header",
				},
				{
					text: "typ kontroly\n(povinné)",
					align: "center",
					value: "typ_kontroly",
					width: 160,
					divider: true,
					class: "header",
				},
				{
					text: "kontakt\n(povinné)",
					align: "center",
					value: "kontakt",
					width: 120,
					divider: true,
					class: "header",
				},
				{
					text: "technik",
					align: "center",
					value: "technik",
					width: 120,
					divider: true,
					class: "header",
				},
				{
					text: "suma",
					align: "center",
					value: "suma",
					width: 75,
					divider: true,
					class: "header",
				},
				{
					text: "vyplatené",
					align: "center",
					value: "datum_vyplatenia",
					width: 120,
					divider: true,
					class: "header",
				},
				{
					text: "zľava",
					align: "center",
					value: "suma_zlava",
					width: 65,
					divider: true,
					class: "header",
				},
				{
					text: "platba",
					align: "center",
					value: "platba",
					width: 75,
					divider: true,
					class: "header",
				},
				{
					text: "výsl.\nTK",
					align: "center",
					value: "vysledok_tk",
					width: 75,
					divider: true,
					class: "header",
				},
				{
					text: "výsl.\nEK",
					align: "center",
					value: "vysledok_ek",
					width: 75,
					divider: true,
					class: "header",
				},
				{
					text: "body\ntechnik",
					align: "center",
					value: "technik_body",
					width: 100,
					divider: true,
					class: "header",
				},
				{
					text: "tringelt",
					align: "center",
					value: "technik_tringelt",
					width: 100,
					divider: true,
					class: "header",
				},
				{
					text: "poznámka",
					align: "center",
					value: "poznamka",
					width: 100,
					divider: true,
					class: "header",
				},
				{
					text: "čakanie\n[minúty]",
					align: "center",
					value: "cakanie",
					width: 75,
					divider: true,
					class: "header",
				},
			],

			headers_basic: [
				{
					text: "",
					value: "cas",
					width: 60,
					divider: true,
					class: "header",
				},
				{
					text: "status auta",
					align: "center",
					value: "status_auta",
					width: 150,
					divider: true,
					class: "header",
				},
				{
					text: "vozidlo\n(povinné)",
					align: "center",
					value: "vozidlo",
					width: 150,
					divider: true,
					class: "header",
				},
				{
					text: "zákazník\n(povinné)",
					align: "center",
					value: "zakaznik",
					width: 150,
					divider: true,
					class: "header",
				},
				{
					text: "typ kontroly\n(povinné)",
					align: "center",
					value: "typ_kontroly",
					width: 150,
					divider: true,
					class: "header",
				},
				{
					text: "kontakt\n(povinné)",
					align: "center",
					value: "kontakt",
					width: 120,
					divider: true,
					class: "header",
				},
				{
					text: "technik",
					align: "center",
					value: "technik",
					divider: true,
					width: 150,
					class: "header",
				},
			],

			empty_records: [],
			records: [],

			status_auta_items: config.OPTIONS.status_auta,
			zakaznik_items: config.OPTIONS.zakaznik,
			zakaznik_kategoria_items: config.OPTIONS.zakaznik_kategoria,
			typ_kontroly_items: config.OPTIONS.typ_kontroly,
			platba_items: config.OPTIONS.platba,
			vysledok_tk_items: config.OPTIONS.vysledok_tk,
			vysledok_ek_items: config.OPTIONS.vysledok_ek,

			filter_value: "",
			filter_column: "",
			filter_counter: 0,
		}
	},

	computed: {
		...mapGetters([
			"date",
			"token",
			"socket",
			"technici",
			"filter",
			"isAdmin",
			"show_self_records",
			"display_name",
		]),
	},

	methods: {
		getIndexAndTimeFromDate(date) {
			// Zistenie času z '2020-09-08 18:45:00' => '18:45'
			const [hour, minute] = new Date(date)
				.toLocaleTimeString("sk-SK")
				.split(":")
			const cas = hour + ":" + minute

			// Zistenie index elementu podľa času
			const index = this.records.findIndex((element) => element.cas === cas)

			return [index, cas]
		},

		async fetchRecordsByDate() {
			// Get date from datetime
			// 2020-12-31

			const isoDate = new Date(
				this.date.getTime() - this.date.getTimezoneOffset() * 60000
			)
				.toISOString()
				.slice(0, 10)

			await axios
				.get(tableSearchWithDate(this.table, isoDate), {
					headers: {
						Authorization: "Token " + this.token,
					},
				})
				.then((response) => {
					// check if response date == this.date
					if (
						new Date(
							this.date.getTime() - this.date.getTimezoneOffset() * 60000
						)
							.toISOString()
							.substr(0, 10) !== response.data.date
					) {
						return 0
					}

					// ak je minulosť
					let today = new Date()
					today.setHours(0, 0, 0, 0)

					// readonly table pre technika
					if (!this.isAdmin) {
						if (this.date.getTime() < today.getTime()) {
							this.isDisabled = true
						} else {
							this.isDisabled = false
						}
					}

					// vyprázdnenie tabulky
					this.records = this.getEmptyData()

					let data = response.data.data

					// počet záznamov
					let count = 0

					// Počítadlo sklzu
					let delay = 0

					// Zistenie času objednania a následný prepis elementu
					data.forEach((item) => {
						let indexAndTime = this.getIndexAndTimeFromDate(
							item.datum_objednania
						)

						item.cas = indexAndTime[1]

						// Overenie ak je čas v rozsahu daných časov
						if (indexAndTime[0] !== -1) {
							const record = new Record(item)

							// vloženie záznamu na určité miesto
							this.records[indexAndTime[0]] = record

							if (delay === 0 && item.status_auta == "prišlo") {
								delay = item.datum_objednania
							}
							count++
						}
					})

					if (delay != 0) {
						delay = Math.round((new Date() - new Date(delay)) / 1000 / 60)
					}

					this.$emit("changeDelay", delay)
					this.$emit("setCount", count)
				})
				.catch((error) => {
					console.log(error)
				})
		},

		async rowChanged(item) {
			// Edit vyplatené
			// 17/1 -> 17. 1. 2021
			if (
				item.datum_vyplatenia != null &&
				item.datum_vyplatenia.match(/\d?\d?\/\d?\d?/)
			) {
				let [day, month] = item.datum_vyplatenia.split("/")
				let vyplateny_datum = new Date()
				vyplateny_datum.setDate(day)
				vyplateny_datum.setMonth(month - 1)
				vyplateny_datum = vyplateny_datum.toLocaleDateString("sk-SK")
				item.datum_vyplatenia = vyplateny_datum
			}

			// technik musí ako kontakt zapísať tel. číslo
			// 9999888444
			// 9999 888 444
			if (!this.isAdmin && item.kontakt !== "") {
				const kontaktWithoutSpaces = item.kontakt.replace(/\s/g, "")
				if (
					!(
						kontaktWithoutSpaces.replace(/[^0-9]/g, "").length ===
							kontaktWithoutSpaces.length && kontaktWithoutSpaces.length === 10
					)
				) {
					this.snackbar = true
					return
				}
			}

			// Overenie či riadok je nový
			if (item.id.toString().includes("temp")) {
				// Vyplnenie kat. zákazníka
				if (!this.isAdmin) {
					item.zakaznik_kategoria = "T"
				}

				// Povinné polia, povinne
				if (
					item.vozidlo === "" ||
					item.zakaznik === "" ||
					item.typ_kontroly === "" ||
					item.zakaznik_kategoria === "" ||
					item.kontakt === ""
				) {
					return
				}

				let item_without_id = item.getSelfData()
				delete item_without_id.id

				// Ziskanie času
				const datetime = new Date(this.date)
				// Rozdelenie hodin a minut do premennych
				const [hour, minute] = item.cas.split(":")
				// Nastavenie času
				datetime.setHours(hour, minute, 0, 0)

				item_without_id.datum_objednania = datetime

				// Automatické pridanie techika
				if (!this.isAdmin) {
					item_without_id.technik = this.display_name
				}

				// Data na odoslanie
				const dataToSend = {
					message: item_without_id,
					table_name: this.table,
					created: true,
					old_id: item.id,
				}

				this.socket.send(JSON.stringify(dataToSend))
				console.log("Created")
			} else {
				// Automatické pridanie technika
				if (!this.isAdmin) {
					item.technik = this.display_name
				}

				const dataToSend = {
					message: item.getSelfData(),
					table_name: this.table,
					created: false,
				}

				this.socket.send(JSON.stringify(dataToSend))
				console.log("UPDATED")
			}
		},

		handleMessageData(messageData) {
			if (messageData.table_name !== this.table) {
				return
			}

			const data = messageData.data
			const messageDate = new Date(data.datum_objednania)

			// ak nová zmena je aktuálny deň
			if (this.date.setHours(0, 0, 0, 0) === messageDate.setHours(0, 0, 0, 0)) {
				//let tempRecords = [...this.records]

				const indexAndTime = this.getIndexAndTimeFromDate(data.datum_objednania)

				data.cas = indexAndTime[1]

				let item = new Record(data)
				item.isActive = true

				if (messageData.created) {
					const newCount = this.count + 1
					this.$emit("setCount", newCount)
				}

				if (messageData.deleted) {
					item = new Record({ id: "temp_" + data.cas, cas: data.cas })

					const newCount = this.count - 1
					this.$emit("setCount", newCount)
				}

				if (indexAndTime[0] !== -1) {
					//tempRecords[indexAndTime[0]] = item
					this.records.splice(indexAndTime[0], 1, item)
				}

				item.rowUpdateColor()

				// zistenie sklzu
				let delay = 0

				const r = this.records.filter(
					(record) => record.status_auta === "prišlo"
				)[0]

				if (r) {
					delay = r.datum_objednania
					delay = Math.round((new Date() - new Date(delay)) / 1000 / 60)
					this.$emit("changeDelay", delay)
				} else {
					this.$emit("changeDelay", 0)
				}
			}
		},

		getEmptyData() {
			let emptyData = []
			config.CASY.forEach((cas) => {
				emptyData.push(new Record({ id: "temp_" + cas, cas: cas }))
			})
			return emptyData
		},

		setItemActive(record) {
			record.isActive = true
		},

		customFilter(_, search, item) {
			item.isActive = false
			if (item[this.filter_column] == search) {
				this.filter_counter += 1

				return true
			}
			return false
		},
	},

	created() {
		this.fetchRecordsByDate()
	},
	mounted() {
		// If date changed
		this.unsubscribe = this.$store.subscribe((mutation) => {
			if (mutation.type === "SET_DATE_STRING") {
				this.fetchRecordsByDate()
			}
		})
	},
	beforeDestroy() {
		this.unsubscribe()
	},

	watch: {
		filter(val) {
			if (!(Object.keys(val).length === 0 && val.constructor === Object)) {
				this.filter_counter = 0
				this.filter_column = val.column
				this.filter_value = val.value

				if (val.value == "") {
					let count = 0
					this.records.forEach((item) => {
						if (!item.id.toString().includes("temp")) {
							count += 1
						}
					})
					setTimeout(() => this.$emit("setCount", count), 0)
				} else {
					setTimeout(() => this.$emit("setCount", this.filter_counter), 0)
				}
				//this.filterRecordsFunction("byFilter", val)
			}
		},
		show_self_records(val) {
			// if filters are empty
			if (
				Object.keys(this.filter).length === 0 &&
				this.filter.constructor === Object
			) {
				this.filter_counter = 0
				this.filter_column = "technik"
				if (val) {
					this.filter_value = this.display_name
					setTimeout(() => this.$emit("setCount", this.filter_counter), 0)
				} else {
					this.filter_value = ""
					let count = 0
					this.records.forEach((item) => {
						if (!item.id.toString().includes("temp")) {
							count += 1
						}
					})
					setTimeout(() => this.$emit("setCount", count), 0)
				}
			}
		},
		ws_data(val) {
			if (val.table_name === this.table) {
				this.handleMessageData(val)
			}
		},
	},
}
</script>

<style>
.planovac td.text-start {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.table-cell {
	border-radius: 0;
	height: 35;
	font-family: Roboto, sans-serif;
	font-size: 16px;

	max-width: 160px;
}

.table-cell-placeholder {
	padding: 4px 12px;
	font-family: Roboto, sans-serif;
	font-size: 16px;
	height: 30px !important;

	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 160px;
	white-space: nowrap;
}

.table-cell-placeholder-technik {
	max-width: 100% !important;
}

.table-cell-border {
	border-right: rgba(192, 192, 192, 0.4) solid 1px;
}

.header {
	background: #1976d2 !important;
	color: white !important;
	font-size: 16px !important;
	font-weight: 400 !important;
	line-height: 17px;
}

.frozen-col-background {
	background: #1976d2 !important;
	padding-right: 0px !important;
	min-width: 60px;
	max-width: 60px;
	height: 30px;
}

.planovac .v-data-table__wrapper {
	overflow: unset;
}

.planovac > .v-data-table__wrapper > table > tbody > tr > td:nth-child(1),
.planovac > .v-data-table__wrapper > table > thead > tr > th:nth-child(1) {
	position: sticky !important;
	position: -webkit-sticky !important;
	left: 0;
	z-index: 4 !important;
}

.planovac > .v-data-table__wrapper > table > thead > tr > th:nth-child(1) {
	z-index: 5 !important;
	border-right: rgba(0, 0, 0, 0.12) solid 1px;
}

.v-text-field .v-input__control .v-input__slot {
	display: flex !important;
	align-items: center !important;
	min-height: auto;
}

.planovac
	> .v-data-table__wrapper
	> table
	> tbody
	> tr
	> td:nth-child(1)
	> div
	> div
	> div
	> div
	> div
	> input {
	color: white !important;
	text-align: right !important;
	padding-right: 7px;
}

.v-data-table.planovac > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table.planovac > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table.planovac > .v-data-table__wrapper > table > tfoot > tr > td {
	height: 0;
}

.v-text-field.v-text-field--solo .v-input__control {
	min-height: auto;
	padding: 0;
}

.v-data-table__divider {
	padding: 0 !important;
}
</style>
